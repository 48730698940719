import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'

import GlobalStyle from '../styles/GlobalStyle'
import Head from '../components/Head'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'

import Color from '../styles/Color'
import Size from '../styles/Size'
import Typography from '../styles/Typography'
import { max959, min960 } from '../styles/Mixin'

const articleTitle = css`
  text-align: left;
  ${max959} {
    padding-right: ${Size(4)};
    padding-left: ${Size(4)};
  }
  ${min960} {
    width: ${Size(180)};
    margin-top: ${Size(20)};
  }
  [class*='headline'] {
    ${Typography.Headline3}
  }
  [class$='note'] {
    text-align: center;
  }
`

const container = css`
  position: relative;
  margin: auto;
  ${max959} {
    width: 100%;
  }
  ${min960} {
    width: 960px;
  }
`

const contents = css`
  ${max959} {
    padding: ${Size(18.5)} ${Size(4)};
  }
  ${min960} {
    padding-top: ${Size(20)};
    padding-bottom: ${Size(20)};
  }
`

const time = css`
  font-size: ${Typography.Body1};
`

const md = css`
  font-size: ${Typography.Body1};

  a {
    color: ${Color.Brown100};
    text-decoration: underline;
  }

  p {
    margin: ${Size(4)} 0;
  }

  img {
    width: auto;
    max-width: 100%;
    border: 0;
    vertical-align: middle;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    line-height: 2;
  }

  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.8rem;
  }

  h5 {
    font-size: 1.6rem;
  }

  blockquote {
    padding-left: ${Size(4)};
    border-left: 0.5em #eee solid;
    margin: 0;
  }

  hr {
    display: block;
    padding: 0;
    border: 0;
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #eee;
    margin: ${Size(4)} 0;
  }

  pre,
  code,
  kbd,
  samp {
    font-family: 'courier new', monospace, sans-serif;
  }

  pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  b,
  strong {
    font-weight: bold;
  }

  dfn {
    font-style: italic;
  }

  ins {
    background: #ff9;
    text-decoration: none;
  }

  mark {
    background: #ff0;
    font-style: italic;
    font-weight: bold;
  }

  sub,
  sup {
    position: relative;
    line-height: 0;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  ul,
  ol {
    padding: 0 0 0 2em;
    margin: ${Size(4)} 0;
  }

  li p:last-child {
    margin: 0;
  }

  dd {
    margin: 0 0 0 ${Size(4)};
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td {
    vertical-align: top;
  }
`

const NewsTemplate = ({ data }) => {
  const {
    markdownRemark: {
      html,
      frontmatter: { title, date, description },
    },
  } = data
  return (
    <>
      <Head title={title} date={date} description={description} />
      <GlobalStyle />
      <Header lower={true} />
      <article>
        <Title text={title} subTitle="News" lower={true} css={articleTitle} />
        <div css={container}>
          <div css={contents}>
            <time dateTime={date} css={time}>
              Date : {date}
            </time>
            <div css={md} dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>
      </article>
      <Footer />
    </>
  )
}

export default NewsTemplate

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        title
        description
      }
    }
  }
`
